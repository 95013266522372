import { config } from '../../../config/config';
import { getClient } from '../../main/graphql';
import {
    GetBoTranslationsDocument,
    type IGetBoTranslationsQuery,
} from '../queries/translations.graphql';

const RTL_LANGUAGES = [
    'ae' /* Avestan */,
    'ar' /* 'العربية', Arabic */,
    'arc' /* Aramaic */,
    'bcc' /* 'بلوچی مکرانی', Southern Balochi */,
    'bqi' /* 'بختياري', Bakthiari */,
    'ckb' /* 'Soranî / کوردی', Sorani */,
    'dv' /* Dhivehi */,
    'fa' /* 'فارسی', Persian */,
    'glk' /* 'گیلکی', Gilaki */,
    'he' /* 'עברית', Hebrew */,
    'ku' /* 'Kurdî / كوردی', Kurdish */,
    'mzn' /* 'مازِرونی', Mazanderani */,
    'nqo' /* N'Ko */,
    'pnb' /* 'پنجابی', Western Punjabi */,
    'ps' /* 'پښتو', Pashto, */,
    'sd' /* 'سنڌي', Sindhi */,
    'ug' /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
    'ur' /* 'اردو', Urdu */,
    'yi' /* 'ייִדיש', Yiddish */,
];

export function isRightToLeft(language: string) {
    return RTL_LANGUAGES.includes(language.toLocaleLowerCase());
}

export function getCanonicalLocale(locale: string): string | boolean {
    try {
        const canonicalLocales = Intl.getCanonicalLocales(locale);

        if (canonicalLocales.length === 0) {
            return false;
        }

        return canonicalLocales[0];
    } catch {
        return false;
    }
}

export function parseLocale(localeString: string) {
    return new Intl.Locale(localeString);
}

export function isValidLocale(locale?: string): locale is string {
    if (typeof locale !== 'string') {
        return false;
    }

    if (getCanonicalLocale(locale) === false) {
        return false;
    }

    if (!config.AVAILABLE_LOCALES.includes(locale)) {
        return false;
    }

    return true;
}

export function urlSplit(url: string): string[] {
    return url.toString().split('/');
}

export const getLocaleMessages = async (locale: string): Promise<Record<string, string>> => {
    const translations = await getClient(true, 'backoffice')
        .query<IGetBoTranslationsQuery>(GetBoTranslationsDocument, {
            locale: locale,
        })
        .toPromise();

    return (
        translations.data?.getBoTranslations?.reduce(
            (acc, item) => {
                acc[item.key] = item.content;
                return acc;
            },
            {} as Record<string, string>,
        ) ?? {}
    );
};
