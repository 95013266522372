import { authExchange } from '@urql/exchange-auth';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { SubscribePayload } from 'graphql-ws';
import { createClient as createWSClient } from 'graphql-ws';
import type { Client } from 'urql';
import {
    createClient,
    fetchExchange,
    subscriptionExchange,
    makeOperation,
} from 'urql';

import { config } from '../../config/config';

export interface IAuthController {
    getToken: () => string;
    willAuthError: () => boolean;
    logout: () => void;
    login: (params: { acrValues?: string; returningOperationId?: string }) => Promise<void>;
    loginSilent: () => Promise<void>;
}

let currentClient: Client | null = null;

let controller: IAuthController;

export const RETURNING_GQL_QUERY_KEY = 'gql-returning-operation';

export function getClient(recreateClient = false, tenantId: string, auth?: IAuthController) {
    if (auth) {
        controller = auth;
    }

    if (!currentClient || recreateClient) {
        currentClient = createGraphQLClient(tenantId);
    }

    return currentClient;
}

function createGraphQLClient(tenantId: string) {
    const wsClient = createWSClient({
        url: config.GRAPH_REALTIME_ENDPOINT,
        connectionParams: {
            headers: {
                'X-Tenant-Id': tenantId,
            },
        },
    });

    return createClient({
        url: config.GRAPH_ENDPOINT,
        exchanges: [
            cacheExchange({
                keys: {
                    UserProfilesConsents: () => null,
                    UserProfilesMarketing: () => null,
                    UserProfilesLastLogin: () => null,
                    UserProfilesAddress: () => null,
                },
            }),
            authExchange(async () => ({
                didAuthError(error) {
                    const isAuthError = error.graphQLErrors.some((e) => {
                        const extension: { exception?: { code: number } } | undefined =
                            e.extensions;
                        return extension?.exception?.code === 403;
                    });

                    return isAuthError;
                },
                async refreshAuth() {
                    try {
                        await controller?.loginSilent();
                    } catch (e) {
                        controller.logout();
                    }
                },
                willAuthError() {
                    return controller?.willAuthError() ?? false;
                },
                addAuthToOperation(operation) {
                    const token = controller?.getToken();

                    if (!token) {
                        return operation;
                    }

                    const fetchOptions =
                        typeof operation.context.fetchOptions === 'function'
                            ? operation.context.fetchOptions()
                            : operation.context.fetchOptions || {};

                    return makeOperation(operation.kind, operation, {
                        ...operation.context,
                        fetchOptions: {
                            ...fetchOptions,
                            headers: {
                                ...fetchOptions.headers,
                                ...(token ? { Authorization: `Bearer ${token}` } : {}),
                            },
                        },
                    });
                },
            })),
            fetchExchange,
            subscriptionExchange({
                forwardSubscription: (request) => ({
                    subscribe: (sink) => ({
                        unsubscribe: wsClient.subscribe(request as SubscribePayload, sink),
                    }),
                }),
            }),
        ],
        fetchOptions: {
            headers: {
                'X-Tenant-Id': tenantId,
            },
        },
    });
}
